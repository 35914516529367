<template>
  <v-container>
    <LoanTypeList/>
  </v-container>
</template>

<script>
import LoanTypeList from "@/components/cib_components/loan_types_sub_component/loan_types_list";

export default {
  name: "LoanTypes",
  components: {LoanTypeList}
}
</script>

<style scoped>

</style>